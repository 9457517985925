import React, { ReactElement, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import mediator from "@tvg/mediator";
import { QuickLink as QuickLinkType } from "@tvg/ts-types/Links";
import { Tag } from "@tvg/design-system";
import { isFDR } from "@tvg/utils/generalUtils";

import {
  Container,
  LinksWrapper,
  ExternalQuickLink,
  QuickLink,
  TagWrapper
} from "./styled-components";

import {
  getQuickLinks,
  getEnableCPPQuickLinks,
  getEnablePromosStoryblok,
  getEnableSGWQuicklinks
} from "./redux/selectors";
import useFetchQuickLinks from "./hooks/useFetchQuickLinks";

const handleQuickLinkClick = (link: QuickLinkType) => {
  mediator.base.dispatch({
    type: "BANNER_CAROUSEL_CLICKED",
    payload: {
      linkUrl: link.url,
      bannerType: link.url.includes("/tutorials") ? "tutorial" : "promo",
      bannerName: link.label,
      bannerId: `SGW-${link.label}`,
      linkText: link.imageURL
    }
  });
};

export const handleQuickLinkViewed = (link: QuickLinkType) => {
  mediator.base.dispatch({
    type: "BANNER_CAROUSEL_VIEWED",
    payload: {
      bannerType: (link.url || "").includes("/tutorials")
        ? "tutorial"
        : "promo",
      bannerName: link.label || "",
      bannerId: `SGW-${(link.label || "").replace(" ", "-")}`
    }
  });
};

const renderTagAndImage = (tag: string, image: ReactElement) => (
  <>
    {tag && (
      <TagWrapper>
        <Tag variant="fav" label={tag} size="s" qaLabel="quickLinkTag" />
      </TagWrapper>
    )}
    {image}
  </>
);

const renderQuickLink = (link: QuickLinkType, image: ReactElement) =>
  link.newTab ? (
    <ExternalQuickLink
      href={link.url}
      target="_blank"
      data-qa-label="quickLinkExternal"
      onClick={() => handleQuickLinkClick(link)}
    >
      {renderTagAndImage(link.tag, image)}
    </ExternalQuickLink>
  ) : (
    <QuickLink
      to={link.url}
      data-qa-label="quickLink"
      onClick={() => handleQuickLinkClick(link)}
    >
      {renderTagAndImage(link.tag, image)}
    </QuickLink>
  );

const QuickLinks = () => {
  const itemRefs = useRef<Array<HTMLLIElement | null>>([]);
  const enableCPPQuickLinks = useSelector(getEnableCPPQuickLinks);
  const enablePromosStoryblok = useSelector(getEnablePromosStoryblok);
  const enableSGWQuicklinks = useSelector(getEnableSGWQuicklinks);
  const quickLinks = useSelector(getQuickLinks);
  const isFdr = isFDR();

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const { target } = entry;
        const quickLinkIndex = itemRefs.current.findIndex((ref) =>
          Object.is(ref, target)
        );

        if (quickLinkIndex >= 0) {
          const foundQuickLink = quickLinks[quickLinkIndex];
          handleQuickLinkViewed(foundQuickLink);
        }
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
    });

    itemRefs.current.forEach((itemRef) => {
      if (itemRef) {
        observer.observe(itemRef);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [quickLinks]);

  useFetchQuickLinks({
    enableCPPQuickLinks,
    enablePromosStoryblok,
    enableSGWQuicklinks,
    isFdr
  });

  useEffect(() => {
    if (isFdr) {
      mediator.base.dispatch({ type: "FETCH_QUICKLINKS" });
    }
  }, []);

  if (quickLinks.length === 0) {
    return null;
  }

  return (
    <Container data-qa-label="quickLinksContainer">
      <LinksWrapper data-qa-label="quickLinkList">
        {quickLinks.map((link: QuickLinkType, index: number) => {
          const linkId = link.id ?? link.label.replace(" ", "-").toLowerCase();
          return (
            <li
              data-qa-label={`quickLink_${linkId}`}
              key={linkId}
              ref={(el) => {
                itemRefs.current[index] = el;
              }}
            >
              {renderQuickLink(
                link,
                <img
                  fetchpriority={index === 0 || index === 1 ? "high" : "low"}
                  src={link.imageURL}
                  data-qa-label="quickLinkImg"
                  alt={link.label}
                />
              )}
            </li>
          );
        })}
      </LinksWrapper>
    </Container>
  );
};

export default QuickLinks;
